<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
// import ElementView from './views/officialWebsite/HomeView.vue'
export default {
  // components: {ElementView},
  data(){
    return {
      message: "Hello Vue"
    }
  },
  methods: {

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-main {
  flex: 1;
}

.el-footer {
  text-align: center;
  background-color: #f5f7fa;
  padding: 15px 5%;
  width: 100%;
}

</style>


