import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/officialWebsite/HomeView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/officialWebsite/HomeView.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('../views/officialWebsite/DownloadView.vue')
  },
  {
    path: '/changelog',
    name: 'changelog',
    component: () => import('../views/officialWebsite/ChangelogView.vue')
  },{
    path: '/empty',
    name: 'empty',
    component: () => import('../views/EmptyView.vue')
  },
  {
    path: '/online',
    name: 'online',
    component: () => import('../views/officialWebsite/OnlineView.vue')
  },
  {
    path: '/forum',
    name: '/forum',
    component: () => import('../views/officialWebsite/ForumView.vue')
  },
  {
    path: '/developer',
    name: 'developer',
    component: () => import('../views/officialWebsite/DeveloperView.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/TestView.vue')
  },
  {
    path: '*',
    name: 'empty',
    component: () => import('../views/EmptyView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
